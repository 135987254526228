import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { store } from "../store";
import { sceneSlice } from "../store/sceneSlice.js";

const loadingManager = new THREE.LoadingManager();
const loader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.4.3/");
loader.setDRACOLoader(dracoLoader);

const handMaterial = new THREE.MeshLambertMaterial({
  color: 0x2ede1b,
});

const eyeMaterial = new THREE.MeshBasicMaterial({
  color: 0xefefef,
});

const pupilMaterial = new THREE.MeshBasicMaterial({
  color: 0x000000,
});

const sphereGeometry = new THREE.SphereGeometry(1, 16, 16);

const DEFAULT_HAND_PROFILE_PATH =
  "https://cdn.jsdelivr.net/npm/@webxr-input-profiles/assets@1.0/dist/profiles/generic-hand/";

const DEFAULT_PROFILES_PATH =
  "https://cdn.jsdelivr.net/npm/@webxr-input-profiles/assets@1.0/dist/profiles/generic-trigger/";

export const joints = [
  "wrist",
  "thumb-metacarpal",
  "thumb-phalanx-proximal",
  "thumb-phalanx-distal",
  "thumb-tip",
  "index-finger-metacarpal",
  "index-finger-phalanx-proximal",
  "index-finger-phalanx-intermediate",
  "index-finger-phalanx-distal",
  "index-finger-tip",
  "middle-finger-metacarpal",
  "middle-finger-phalanx-proximal",
  "middle-finger-phalanx-intermediate",
  "middle-finger-phalanx-distal",
  "middle-finger-tip",
  "ring-finger-metacarpal",
  "ring-finger-phalanx-proximal",
  "ring-finger-phalanx-intermediate",
  "ring-finger-phalanx-distal",
  "ring-finger-tip",
  "pinky-finger-metacarpal",
  "pinky-finger-phalanx-proximal",
  "pinky-finger-phalanx-intermediate",
  "pinky-finger-phalanx-distal",
  "pinky-finger-tip",
];

loadingManager.onLoad = () => {
  store.dispatch(sceneSlice.actions.sceneLoaded());
};

loadingManager.onError = (url) => {
  console.error("There was an error loading " + url);
};

export async function createHand(handedness, color) {
  const gltf = await loader.loadAsync(`${DEFAULT_HAND_PROFILE_PATH}${handedness}.glb`);
  const hand = gltf.scene.children[0];
  const bones = [];

  const mesh = hand.getObjectByProperty("type", "SkinnedMesh");
  mesh.frustumCulled = false;
  // mesh.castShadow = true;
  // mesh.receiveShadow = true;

  const newMaterial = new THREE.MeshLambertMaterial({
    color,
  });

  mesh.traverse(function (node) {
    if (node.isMesh) {
      node.material = newMaterial;
      node.material.needsUpdate = true;
    }
  });

  joints.forEach((jointName) => {
    const bone = hand.getObjectByName(jointName);

    if (bone !== undefined) {
      bone.jointName = jointName;
    } else {
      console.warn(`Couldn't find ${jointName} in ${handedness} hand mesh`);
    }

    bones.push(bone);
  });

  return { hand, bones };
}

export async function createController(handedness, color) {
  const gltf = await loader.loadAsync(`${DEFAULT_PROFILES_PATH}${handedness}.glb`);
  const controller = gltf.scene;

  const newMaterial = new THREE.MeshLambertMaterial({
    color,
  });

  controller.frustumCulled = false;
  // controller.castShadow = true;
  // controller.receiveShadow = true;

  controller.traverse(function (node) {
    if (node.isMesh) {
      node.material = newMaterial;
      node.material.needsUpdate = true;
    }
  });

  return { controller };
}

export async function createElement(url, transparent) {
  const gltf = await loader.loadAsync(url);
  const element = gltf.scene;
  if (transparent) {
    element.traverse(function (node) {
      if (node.isMesh) {
        var newMaterial = new THREE.MeshBasicMaterial({
          color: 0xffffff,
        });
        newMaterial.opacity = 0.7;
        newMaterial.transparent = true;
        node.material = newMaterial;
        node.material.needsUpdate = true;
      }
    });
  }
  return element;
}

export function createAvatar(color) {
  const avatar = new THREE.Group();
  avatar.scale.set(0.4, 0.4, 0.4);

  const headMaterial = new THREE.MeshLambertMaterial({
    color,
  });

  const head = new THREE.Mesh(sphereGeometry, headMaterial);
  head.scale.set(0.45, 0.5, 0.4);
  avatar.add(head);

  const face = new THREE.Group();
  face.position.set(0, 0.05, 0);
  avatar.add(face);

  const eye1 = new THREE.Mesh(sphereGeometry, eyeMaterial);
  eye1.scale.set(0.12, 0.12, 0.12);
  eye1.position.set(0.16, 0.1, -0.35);
  face.add(eye1);

  const pupil1 = new THREE.Mesh(sphereGeometry, pupilMaterial);
  pupil1.scale.set(0.2, 0.2, 0.2);
  pupil1.position.set(0, 0, -1);
  eye1.add(pupil1);

  const eye2 = new THREE.Mesh(sphereGeometry, eyeMaterial);
  eye2.scale.set(0.12, 0.12, 0.12);
  eye2.position.set(-0.16, 0.1, -0.35);
  face.add(eye2);

  const pupil2 = new THREE.Mesh(sphereGeometry, pupilMaterial);
  pupil2.scale.set(0.2, 0.2, 0.2);
  pupil2.position.set(0, 0, -1);
  eye2.add(pupil2);

  return avatar;
}

export function clearGroup(group) {
  var length = group.children.length;

  for (var i = length - 1; i >= 0; i--) {
    if (group.children[i].geometry) group.children[i].geometry.dispose();
    if (group.children[i].material) group.children[i].material.dispose();
    if (group.children[i].texture) group.children[i].texture.dispose();
    group.remove(group.children[i]);
  }
}

export function createTunnelVignette() {
  const canvas = document.createElement("CANVAS");
  canvas.width = 64;
  canvas.height = 64;

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const grd = ctx.createLinearGradient(0, 0, 0, 64);
  grd.addColorStop(0, "#000000ff");
  grd.addColorStop(0.6, "#000000ff");
  grd.addColorStop(0.9, "#00000000");

  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, 64, 64);

  let vignetteMap= new THREE.CanvasTexture(canvas);

  const vignette = new THREE.Mesh(
    new THREE.CylinderGeometry(0.2, 0.2, 0.4, 32, 1, true),
    new THREE.MeshBasicMaterial({
      side: THREE.BackSide,
      transparent: true,
      depthWrite: false,
    })
  );
  vignette.renderOrder = 1000;
  vignette.material.depthTest = false;
  vignette.material.depthWrite = false;
  vignette.frustumCulled = false;
  vignette.rotateX(Math.PI / 2);
  vignette.position.z = 0.4 / 2;
  vignette.material.map = vignetteMap;
  return vignette;
}

export const colors = [
  "Blue",
  "Coral",
  "Cyan",
  "DarkMagenta",
  "DarkOliveGreen",
  "DarkOrange",
  "DarkRed",
  "DarkSeaGreen",
  "DeepPink",
  "Fuchsia",
  "Green",
  "Khaki",
  "LemonChiffon",
  "Navy",
  "OrangeRed",
  "Red",
  "Snow",
  "SpringGreen",
  "SteelBlue",
  "Tan",
  "Teal",
  "Thistle",
  "Tomato",
  "Turquoise",
  "Violet",
  "Wheat",
  "White",
  "WhiteSmoke",
  "Yellow",
  "YellowGreen",
];
